var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"debounce":100,"name":"Banco","vid":"banco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.items,"label":_vm.propTitle,"autocomplete":"new_banco","filter":_vm.filter,"hide-no-data":"","item-text":"nome","name":"new_banco","return-object":"","type":"search","disabled":_vm.propDisabled},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.codigo)+" - "+_vm._s(item.nome)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return (item.nome)?[_vm._v(" "+_vm._s(item.codigo)+" - "+_vm._s(item.nome)+" ")]:undefined}}],null,true),model:{value:(_vm.selectedBanco),callback:function ($$v) {_vm.selectedBanco=$$v},expression:"selectedBanco"}})]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }