import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { contaBancariaStore, mainStore } from '@/utils/store-accessor';
import _ from 'lodash';
let ContaBancariaTransferirComponent = class ContaBancariaTransferirComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.selectedContaBancaria = null;
        this.observacaoTransferencia = null;
        this.valorMovimentacao = 0;
    }
    get contasBancarias() {
        return _.filter(contaBancariaStore.contasBancarias, (item) => item.id != this.propContaBancariaId);
    }
    get contaBancaria() {
        return _.find(contaBancariaStore.contasBancarias, (item) => item.id == this.propContaBancariaId);
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get tipoMovimentacaoText() {
        if (this.propTipoMovimentacao === 1) {
            return 'entrada';
        }
        if (this.propTipoMovimentacao === 2) {
            return 'saída';
        }
        if (this.propTipoMovimentacao === 3) {
            return 'transferência';
        }
    }
    async saveMovimentacao() {
        const objMovimentacao = {
            data_hora: new Date().toISOString(),
            valor: this.valorMovimentacao,
            descricao: this.observacaoTransferencia,
            conta_id: this.propContaBancariaId,
            tipo: this.propTipoMovimentacao,
            conta_enviou_id: null,
            conta_recebeu_id: null,
        };
        if (this.propTipoMovimentacao === 1) {
            await contaBancariaStore.createContaBancariaMovimentacoes([
                objMovimentacao,
            ]);
        }
        else if (this.propTipoMovimentacao === 2) {
            objMovimentacao.valor = 0 - objMovimentacao.valor;
            await contaBancariaStore.createContaBancariaMovimentacoes([
                objMovimentacao,
            ]);
        }
        else if (this.propTipoMovimentacao === 3) {
            objMovimentacao.conta_enviou_id = objMovimentacao.conta_id;
            objMovimentacao.conta_recebeu_id = this.selectedContaBancaria.id;
            const objMovimentacaoAlvo = {
                ...objMovimentacao,
                conta_id: this.selectedContaBancaria.id,
                descricao: `Tranferência recebida de ${this.contaBancaria.nome}`,
            };
            objMovimentacao.valor = 0 - objMovimentacaoAlvo.valor;
            await contaBancariaStore.createContaBancariaMovimentacoes([
                objMovimentacao,
                objMovimentacaoAlvo,
            ]);
        }
        this.dialog = false;
        this.$emit('onSave');
        this.selectedContaBancaria = null;
        this.observacaoTransferencia = null;
        this.valorMovimentacao = 0;
    }
    async mounted() {
        await contaBancariaStore.getContasBancarias(true);
        if (this.propTipoMovimentacao === 1) {
            this.observacaoTransferencia = `Entrada manual`;
        }
        else if (this.propTipoMovimentacao === 2) {
            this.observacaoTransferencia = `Saída manual`;
        }
        else {
            this.observacaoTransferencia = `Tranferência entre contas `;
        }
    }
};
__decorate([
    Prop()
], ContaBancariaTransferirComponent.prototype, "propContaBancariaId", void 0);
__decorate([
    Prop()
], ContaBancariaTransferirComponent.prototype, "propTipoMovimentacao", void 0);
ContaBancariaTransferirComponent = __decorate([
    Component
], ContaBancariaTransferirComponent);
export default ContaBancariaTransferirComponent;
