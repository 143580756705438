export function newContaBancaria() {
    return {
        // tipo: '',
        nome: '',
        banco_id: null,
        conta_padrao: false,
        agencia: '',
        conta: '',
        company_id: 0,
        is_active: true,
    };
}
