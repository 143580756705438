import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { bancoStore } from '@/store';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let SearchBancoComponent = class SearchBancoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.validationObj = {};
        this.search = null;
        this.selectedBanco = {};
        this.firstTime = true;
    }
    get items() {
        return bancoStore.bancos;
    }
    filter(item, queryText, itemText) {
        const texto = `${item.codigo} - ${item.nome}`;
        return (texto.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1);
    }
    async validate() {
        return await this.$refs.observer.validate();
    }
    async onValidationUpdate(val) {
        this.validationObj = val;
    }
    async onChangeBanco(val) {
        if (val === null) {
            return;
        }
        // @ts-ignore
        this.id = this.selectedBanco.id;
        // @ts-ignore
        this.codigo = this.selectedBanco.codigo;
        // @ts-ignore
        this.nome = this.selectedBanco.nome;
        this.$emit('input', val);
        await this.$refs.observer.validate();
    }
    async mounted() {
        await bancoStore.getBancos();
        if (this.value) {
            this.selectedBanco = this.value;
        }
    }
};
__decorate([
    Prop(Object)
], SearchBancoComponent.prototype, "propValidationObj", void 0);
__decorate([
    Prop()
], SearchBancoComponent.prototype, "value", void 0);
__decorate([
    Prop({ default: 'Banco' })
], SearchBancoComponent.prototype, "propTitle", void 0);
__decorate([
    Prop({ default: false, required: false, type: Boolean })
], SearchBancoComponent.prototype, "propDisabled", void 0);
__decorate([
    Watch('propValidationObj', { deep: true })
], SearchBancoComponent.prototype, "onValidationUpdate", null);
__decorate([
    Watch('selectedBanco')
], SearchBancoComponent.prototype, "onChangeBanco", null);
SearchBancoComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
    })
], SearchBancoComponent);
export default SearchBancoComponent;
